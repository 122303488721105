import {
  AvailableLanguages,
  Clinic,
  clinicClient,
  ContactMethod,
  ElementTracker,
  ElementTrackerType,
  FUNNEL_VERSION,
  getBMI,
  isEmailValid,
  isHeightFeetValid,
  isHeightInchesValid,
  isValidPassword,
  isWeightLbsValid,
  LeadOnboardingStage,
  MembershipType,
  Metadata,
  MixpanelClient,
  MixpanelEvent,
  onboardingClient,
  Partner,
  PRIVACY_POLICY,
  ReferralLandingInfo,
  ReferralRawInfo,
  referralsClient,
  reportErrorToHoneybadger,
  StatsigEventName,
  StatsigManager,
  TERMS_OF_USE,
  userClient,
} from '@enaratech/funnel-helper';
import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Agreements, { RefAgreements } from 'src/components/Common/Agreements/Agreements';
import ViewIcon from 'src/components/Common/Icons/ViewIcon';
import ViewIconOff from 'src/components/Common/Icons/ViewIconOff';
import LoadingIndicator from 'src/components/Common/LoadingIndicator/LoadingIndicator';
import Toast from 'src/components/Common/Toast/Toast';
import BasicLayout from 'src/components/Layout/BasicLayout/BasicLayout';
import { useClinic } from 'src/contexts/clinic';
import { ClinicWithAvailablePartners } from 'src/contexts/clinic/types';
import { useExperiment } from 'src/contexts/experiments';
import { SET_USER_METADATA } from 'src/contexts/experiments/types';
import { useRoutePath } from 'src/hooks/useRoutePath';
import ReferralPartnerOptions from 'src/pages/Partners/PartnerOptions/ReferralPartnerOptions';
import { expandExperimentUser } from '../../../../lib/experiments';
import {
  CreateReferralAccountErrors,
  CreateReferralAccountForm,
} from './createReferralAccount.types';
import { FieldMinLength, inputFields, InputFieldsForBMI } from './inputFields';

type Props = {
  landingInfo: ReferralLandingInfo;
  rawInfo: ReferralRawInfo;
  dateOfBirth: string;
  onCreateReferralAccount: (membershipType?: MembershipType) => void;
};

type PartnerTuple = {
  id: number;
  name: string;
};

type ClinicInfoById = { [id: number]: Clinic };

const CreateReferralAccount: FC<Props> = ({
  landingInfo,
  rawInfo,
  dateOfBirth,
  onCreateReferralAccount,
}) => {
  const [formState, setFormState] = useState<CreateReferralAccountForm>({
    email: '',
    password: '',
    confirmPassword: '',
    heightFeet: '',
    heightInches: '',
    weightLbs: '',
  });
  const [errors, setErrors] = useState<Partial<CreateReferralAccountErrors> | null>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<{
    password: boolean;
    confirmPassword: boolean;
  }>({
    password: false,
    confirmPassword: false,
  });
  const [clinicInfo, setClinicInfo] = useState<Clinic | null>(null);
  const [agreementsLoaded, setAgreementsLoaded] = useState<boolean>(false);
  const [allAgreementsSelected, setAllAgreementsSelected] = useState<boolean>(false);

  // Special flow rules:

  // When we need to manually request BMI
  const [isComputingBMI, setComputingBMI] = useState<boolean>(false);

  // When we need to manually request to select a starting partner clinic
  const [isSelectingClinic, setIsSelectingClinic] = useState<boolean>(false);
  const [availablePartners, setAvailablePartners] = useState<Partner[]>([]);
  const [clinicsInfoById, setClinicsInfoById] = useState<ClinicInfoById | null>(null);
  const [selectedPartner, setSelectedPartner] = useState<Partner | null>(null);

  const agreementsRef = useRef<RefAgreements>(null);

  const routePath = useRoutePath();
  const navigate = useNavigate();
  const {
    experimentState: { isInitialized },
    dispatchExperimentState,
  } = useExperiment();

  const { clinicState } = useClinic();

  const updateClinicInfo = async (clinicZipCode: string) => {
    setClinicInfo(null);

    try {
      const clinicInfo = await clinicClient.fetchClosestClinicByZipCode(clinicZipCode, true);
      setClinicInfo(clinicInfo);
    } catch (error) {
      Toast.notification('error', 'Could not resolve clinic information for zip code');
    }
  };

  const loadAllClinicsInfo = async (
    state: ClinicWithAvailablePartners,
    matchingPartner: Partner
  ) => {
    if (availablePartners.length > 0) {
      return;
    }

    const relatedPartners = matchingPartner.referralRelatedClinics.map(
      (c) => state.partners.find((p) => p.clinicId === c.id)!
    );
    const partners = [matchingPartner, ...relatedPartners];
    const promises: Promise<Clinic | null>[] = [];

    for (const availablePartner of partners) {
      promises.push(
        clinicClient
          .fetchClosestClinicByZipCode(`${availablePartner.zipCode}`, true)
          .catch((error) => null)
      );
    }

    const allClinicsInfo = await Promise.all(promises);
    const clinicInfoById: ClinicInfoById = {};

    for (const clinicInfo of allClinicsInfo) {
      if (!!clinicInfo) {
        clinicInfoById[clinicInfo.details.clinicId] = clinicInfo;
      }
    }

    setClinicsInfoById(clinicInfoById);
    setAvailablePartners(partners);
  };

  const handleSelectReferralPartner = (partnerTuple: PartnerTuple | null) => {
    if (!partnerTuple) {
      setSelectedPartner(null);
      return;
    }

    if (!clinicState || !clinicState.partners) {
      console.warn('No partners info yet, waiting');
      return;
    }

    const matchingPartner = clinicState.partners.find((c) => c.clinicId === partnerTuple.id);

    if (!matchingPartner) {
      console.error('No matching partner, unexpected error');
      return;
    }

    setSelectedPartner(matchingPartner);
    const preloadedInfo = clinicsInfoById![matchingPartner.clinicId];

    if (!preloadedInfo) {
      updateClinicInfo(String(matchingPartner.zipCode));
      return;
    }

    setClinicInfo(preloadedInfo);
  };

  useEffect(() => {
    if (!isInitialized) {
      return;
    }
  }, [isInitialized]);

  useEffect(() => {
    const newState = { ...formState };

    if (rawInfo.email) {
      newState.email = rawInfo.email;
    }

    if (landingInfo.member.bmi) {
      setComputingBMI(false);
    } else {
      setComputingBMI(true);
    }

    setFormState(newState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rawInfo.email, landingInfo.member.bmi]);

  useEffect(() => {
    if (!clinicState || !clinicState.partners) {
      console.warn('No partners info yet, waiting');
      return;
    }

    const clinic = rawInfo.clinic;
    if (!clinic) {
      console.warn('No raw info yet, waiting for clinic');
      return;
    }

    const matchingPartner =
      selectedPartner || clinicState.partners.find((c) => c.clinicId === clinic.clinicId);

    if (!matchingPartner) {
      console.error('No matching partner, unexpected error');
      Toast.notification(
        'error',
        'Could not retrieve clinics information. Please contact Tech Support for further assistance.'
      );
      reportErrorToHoneybadger({
        error: `It was not possible to find a matching referral partner. clinicId:${
          clinic.clinicId
        }. partners:[${clinicState.partners.map((p) => p.clinicId).join(',')}]`,
      });
      return;
    }

    const withRelatedClinics = matchingPartner.referralRelatedClinics.length > 0;

    if (withRelatedClinics) {
      // User needs to manually select one partner first before continue
      setIsSelectingClinic(true);

      // And zip code fetching will be done after selecting a particular clinic (inside handleSelectClinic) and preloaded here
      loadAllClinicsInfo(clinicState, matchingPartner);
      return;
    } else {
      // For this case, we continue the regular flow, selecting the partner automatically
      setIsSelectingClinic(false);
      setSelectedPartner(matchingPartner);
      setAvailablePartners([]);

      // And whe update the info coming from the fetch by zip code endpoint for the selected partner
      const clinicZipCode = String(matchingPartner.zipCode || clinic.zipCode || rawInfo.zipCode);
      updateClinicInfo(clinicZipCode);
    }
  }, [selectedPartner, clinicState, rawInfo, navigate]);

  const handleOnLoadAgreements = useCallback(() => setAgreementsLoaded(true), []);
  const handleOnSelectAgreements = useCallback((all: boolean) => setAllAgreementsSelected(all), []);

  const validateForm = (field: string, value: string) => {
    const updatedFormState = { ...formState, [field]: value };

    if (!updatedFormState[field as keyof typeof updatedFormState]) {
      setErrors((prev) => ({
        ...prev,
        [field]: [
          `The ${
            field === 'confirmPassword' ? 'confirm password' : field.toLowerCase()
          } is required`,
        ],
      }));
    }

    if (updatedFormState[field as keyof typeof updatedFormState].length > 0) {
      setErrors((prev) => ({ ...prev, [field]: [] }));
    }

    if (field === InputFieldsForBMI.HeightFeet && !isHeightFeetValid(value)) {
      setErrors((prev) => ({ ...prev, [field]: ['Minimum must be 1 and maximum 10'] }));
    } else if (field === InputFieldsForBMI.HeightInches && !isHeightInchesValid(value)) {
      setErrors((prev) => ({ ...prev, [field]: ['Minimum must be 0 and maximum 11'] }));
    } else if (field === InputFieldsForBMI.WeightLbs && !isWeightLbsValid(value)) {
      setErrors((prev) => ({
        ...prev,
        [field]: ['Weight field minimum must be 10 and maximum 999'],
      }));
    }

    if (field === 'email' && !isEmailValid(updatedFormState.email)) {
      setErrors((prev) => ({
        ...prev,
        [field]: ['Sorry, only letters (a-z), numbers (0-9), and periods (.) are allowed.'],
      }));
    }

    if (['password', 'confirmPassword'].includes(field)) {
      if (!isValidPassword(updatedFormState.password)) {
        setErrors((prev) => ({
          ...prev,
          password: [
            'A lowercase letter (a-z)',
            'An uppercase letter (A-Z)',
            'A Number (0-9)',
            'Being at least 8 characters',
          ],
        }));
      } else if (updatedFormState.confirmPassword.length > 0) {
        setErrors((prev) => ({
          ...prev,
          confirmPassword:
            updatedFormState.confirmPassword !== updatedFormState.password
              ? ['Passwords do not match']
              : [],
        }));
      }
    }

    setFormState(updatedFormState);
  };

  const handleSubmit = async () => {
    const { email, password } = formState;

    setIsSubmitting(true);

    if (!(await userClient.checkAvailability(email))) {
      Toast.notification('error', 'The email address is already in use');
      return setIsSubmitting(false);
    }

    const { details, program } = clinicInfo!;
    const { member, contactMethod: referralContactMethod } = landingInfo;

    const clinicId = details.clinicId;
    const systemSource = referralContactMethod || ContactMethod.PartnerReferral;

    const bmi = isComputingBMI
      ? getBMI({
          weight: Number(formState.weightLbs),
          feet: Number(formState.heightFeet),
          inches: Number(formState.heightInches),
        }).toString()
      : member.bmi!.toFixed(2);

    const user = await userClient.createReferralUser({
      city: details.city,
      clinicId: clinicId.toString(),
      contactMethod: systemSource,
      dateOfBirth,
      email,
      firstName: member.firstName,
      gender: member.gender,
      lastName: member.lastName,
      leadsquaredId: rawInfo.leadsquaredId!,
      password,
      phone: rawInfo.phone,
      salesforceId: rawInfo.salesforceId,
      state: details.state,
      uuid: rawInfo.uuid,
      zipCode: rawInfo.zipCode!,
      clinicZipCode: details.zipCode,
      programType: program,
      bmi: Number(bmi),
    });

    if (!user) {
      Toast.notification('error', 'User could not be created');

      MixpanelClient.trackEvent({
        event: MixpanelEvent.Show,
        properties: {
          field: 'Error message',
          value: 'Could not create user',
          source: routePath,
        },
      });

      return setIsSubmitting(false);
    }

    const linkedUser = await referralsClient.linkReferralToUser(user.id, {
      dateOfBirth,
      internalFullName: member.internalFullName,
    });

    const userMetadata = {
      email,
      firstName: member.firstName,
      lastName: member.lastName,
      programType: program,
      clinicId,
      referral: 'fax',
      ...(linkedUser?.eligibility?.membershipType
        ? { membershipType: linkedUser.eligibility.membershipType }
        : {}),
    };

    MixpanelClient.identityAlias(user.id);
    MixpanelClient.setMetadata({
      ...userMetadata,
      isNewMember: false,
      experiments: StatsigManager.getExperimentsSummary(),
    } as Partial<Metadata>);

    // Updating user for experiments dashboard
    StatsigManager.logEvent({
      eventName: StatsigEventName.AccountCreation,
      metadata: {
        ...userMetadata,
        clinicId: `${clinicId}`,
        memberId: `${user.id}`,
        memberUuid: user.uuid,
        systemSource,
      },
    });

    dispatchExperimentState({
      type: SET_USER_METADATA,
      payload: expandExperimentUser(user.id, {
        appVersion: FUNNEL_VERSION,
        custom: { ...userMetadata, systemSource },
        email: userMetadata.email,
        userID: user.uuid,
      }),
    });

    userClient.createDefaultProviders();

    const { phone, insurance } = linkedUser!;

    await userClient.updateUser({
      insuranceCompany: insurance.carrier,
      insuranceId: insurance.policyNumber,
      phone,
    });

    onboardingClient.updateMemberStatus({
      onboardingStage: LeadOnboardingStage.ReferralAccountCreation,
      firstLanguage: AvailableLanguages.English,
      groupId: insurance.groupNumber,
    });

    if (agreementsRef.current) {
      agreementsRef.current.signAgreements();
    }

    onCreateReferralAccount(userMetadata.membershipType);
  };

  if (isSelectingClinic && availablePartners.length === 0) {
    return <LoadingIndicator />;
  }

  return (
    <BasicLayout
      title={`Welcome to the Enara Journey, ${landingInfo.member.firstName}!`}
      subtitle='Let’s setup your account and take the first step toward achieving your health goals'
      buttonProps={{
        disabled:
          Object.entries(formState).some(([key, value]) => value.length < FieldMinLength[key]) ||
          (!!errors && Object.values(errors).some((i) => i.length > 0)) ||
          !clinicInfo ||
          !agreementsLoaded ||
          !allAgreementsSelected ||
          (isSelectingClinic && !selectedPartner),
        loading: isSubmitting,
        onClick: handleSubmit,
      }}
      back>
      <form>
        {isSelectingClinic && availablePartners.length > 0 && (
          <Box sx={{ marginBottom: 4 }}>
            <Typography variant={'h4'}>We want to know you better</Typography>
            <Typography paragraph variant={'h5'} className='typography-bmi'>
              (Please select your preferred Clinic Site)
            </Typography>
            <Stack spacing='10px'>
              <Box className='radio-box'>
                <ReferralPartnerOptions
                  onChange={handleSelectReferralPartner}
                  selectedPartner={
                    selectedPartner
                      ? { id: selectedPartner.clinicId, name: selectedPartner.name }
                      : null
                  }
                  availablePartners={availablePartners.map((p) => ({
                    id: p.clinicId,
                    name: p.name,
                  }))}
                />
              </Box>
            </Stack>
          </Box>
        )}

        {/** TODO: Separate this as a new component */}
        {isComputingBMI && (
          <Box sx={{ marginBottom: 2 }}>
            <Typography variant={'h4'}>Could you share your height and weight with us?</Typography>
            <Typography paragraph variant={'h5'} className='typography-bmi'>
              (This helps us calculate your BMI, an important step for insurance coverage and
              supporting your journey)
            </Typography>
            <Stack spacing='10px'>
              <Stack direction={{ xs: 'column', md: 'row' }} spacing='10px'>
                <ElementTracker routePath={routePath} name='Height Feet'>
                  <TextField
                    id={InputFieldsForBMI.HeightFeet}
                    label={'ft.'}
                    variant='filled'
                    inputProps={{ placeholder: 'ft.' }}
                    value={formState.heightFeet || ''}
                    className='get-started-input'
                    onChange={(e) => validateForm(InputFieldsForBMI.HeightFeet, e.target.value)}
                    error={!!errors?.[InputFieldsForBMI.HeightFeet as keyof typeof errors]?.length}
                    helperText={
                      !!errors?.[InputFieldsForBMI.HeightFeet as keyof typeof errors] && (
                        <ul>
                          {(
                            Object.values(
                              errors[InputFieldsForBMI.HeightFeet as keyof typeof errors]!
                            ) as string[]
                          ).map((entry, i) => (
                            <li key={`referral-password-hint-${i}`}>{entry}</li>
                          ))}
                        </ul>
                      )
                    }
                  />
                </ElementTracker>
                <ElementTracker routePath={routePath} name='Height Inches'>
                  <TextField
                    id={InputFieldsForBMI.HeightInches}
                    label={'in.'}
                    variant='filled'
                    inputProps={{ placeholder: 'in.' }}
                    value={formState.heightInches || ''}
                    className='get-started-input'
                    onChange={(e) => validateForm(InputFieldsForBMI.HeightInches, e.target.value)}
                    error={
                      !!errors?.[InputFieldsForBMI.HeightInches as keyof typeof errors]?.length
                    }
                    helperText={
                      !!errors?.[InputFieldsForBMI.HeightInches as keyof typeof errors] && (
                        <ul>
                          {(
                            Object.values(
                              errors[InputFieldsForBMI.HeightInches as keyof typeof errors]!
                            ) as string[]
                          ).map((entry, i) => (
                            <li key={`referral-password-hint-${i}`}>{entry}</li>
                          ))}
                        </ul>
                      )
                    }
                  />
                </ElementTracker>
              </Stack>
              <ElementTracker routePath={routePath} name='Weight Lbs'>
                <TextField
                  id={InputFieldsForBMI.WeightLbs}
                  label={'lbs.'}
                  variant='filled'
                  value={formState.weightLbs || ''}
                  className='get-started-input'
                  inputProps={{ placeholder: 'lbs.' }}
                  onChange={(e) => validateForm(InputFieldsForBMI.WeightLbs, e.target.value)}
                  error={!!errors?.[InputFieldsForBMI.WeightLbs as keyof typeof errors]?.length}
                  helperText={
                    !!errors?.[InputFieldsForBMI.WeightLbs as keyof typeof errors] && (
                      <ul>
                        {(
                          Object.values(
                            errors[InputFieldsForBMI.WeightLbs as keyof typeof errors]!
                          ) as string[]
                        ).map((entry, i) => (
                          <li key={`referral-password-hint-${i}`}>{entry}</li>
                        ))}
                      </ul>
                    )
                  }
                />
              </ElementTracker>
            </Stack>
          </Box>
        )}

        <Box>
          <Typography variant={'h4'}>Unlock Your Enara Experience</Typography>
          <Typography paragraph variant={'h5'} className='typography-bmi'>
            (Create your log-in credentials to access the Enara App and begin your journey)
          </Typography>
        </Box>

        <Grid container spacing={2} sx={{ marginBottom: 3 }}>
          {inputFields.map(({ label, field, name }) => (
            <Grid item xs={12} sm={6} key={`create-account-grid-${field}`} sx={{ marginTop: 1 }}>
              <ElementTracker
                routePath={routePath}
                name={name}
                type={ElementTrackerType.Blurrable}
                value={
                  ['Confirm Password', 'Password'].includes(name)
                    ? !!errors?.[field as keyof typeof errors] === false
                    : undefined
                }>
                <TextField
                  data-test={`createAccount-input-${field}`}
                  name={name}
                  label={label}
                  fullWidth
                  variant='filled'
                  value={formState[field as keyof CreateReferralAccountForm]}
                  type={
                    !['confirmPassword', 'password'].includes(field)
                      ? 'text'
                      : showPassword[field as keyof typeof showPassword]
                      ? 'text'
                      : 'password'
                  }
                  // @ts-ignore,
                  FormHelperTextProps={{ component: 'span' }}
                  InputProps={{
                    endAdornment: ['confirmPassword', 'password'].includes(field) && (
                      <InputAdornment position='end'>
                        <IconButton
                          onClick={() =>
                            setShowPassword({
                              ...showPassword,
                              [field]: !showPassword[field as keyof typeof showPassword],
                            })
                          }>
                          {showPassword[field as keyof typeof showPassword] ? (
                            <ViewIcon />
                          ) : (
                            <ViewIconOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  autoComplete={field}
                  onChange={(e) => validateForm(field, e.target.value)}
                  error={!!errors?.[field as keyof typeof errors]?.length}
                  helperText={
                    !!errors?.[field as keyof typeof errors] && (
                      <ul>
                        {(Object.values(errors[field as keyof typeof errors]!) as string[]).map(
                          (entry, i) => (
                            <li key={`referral-password-hint-${i}`}>{entry}</li>
                          )
                        )}
                      </ul>
                    )
                  }
                />
              </ElementTracker>
            </Grid>
          ))}
        </Grid>
      </form>

      <Typography variant='h6'>
        By clicking in Next, you agree to Enara Health{' '}
        <ElementTracker
          routePath={routePath}
          name='Link terms of use'
          type={ElementTrackerType.Clickable}>
          <Link id='term-of-use' href={TERMS_OF_USE} target='_blank'>
            <strong>Terms of Use</strong>
          </Link>
        </ElementTracker>{' '}
        and{' '}
        <ElementTracker
          routePath={routePath}
          name='Link privacy policy'
          type={ElementTrackerType.Clickable}>
          <Link id='privacy-policy' href={PRIVACY_POLICY} target='_blank'>
            <strong>Privacy Policy</strong>
          </Link>
        </ElementTracker>
      </Typography>

      <Box my={2}>
        <Agreements
          ref={agreementsRef}
          agreementsMode={'public'}
          onLoaded={handleOnLoadAgreements}
          onSelect={handleOnSelectAgreements}
        />
      </Box>
    </BasicLayout>
  );
};

export default CreateReferralAccount;
