import {
  ContactMethod,
  ElementTracker,
  ElementTrackerType,
  HeightWeightForm,
  MixpanelClient,
  NotQualified,
  ProgressKey,
  StatsigEventName,
  StatsigManager,
  getAnonymousProgress,
  getBMI,
  isEmailValid,
  isHeightFeetValid,
  isHeightInchesValid,
  isLowBMI,
  isWeightLbsValid,
  onboardingClient,
  storeAnonymousProgress,
} from '@enaratech/funnel-helper';
import { Box, Stack, TextField, Typography } from '@mui/material';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Page, withProgress } from 'src/components/Common/Progress/Progress';
import TermsOfUse from 'src/components/Common/TermsOfUse/TermsOfUse';
import BasicLayout from 'src/components/Layout/BasicLayout/BasicLayout';
import { useExperiment } from 'src/contexts/experiments';
import { useRoutePath } from 'src/hooks/useRoutePath';
import { navigateToPage } from '../routes';
import './scss/getStarted.scss';

enum InputFields {
  FirstName = 'firstName',
  LastName = 'lastName',
  HeightFeet = 'heightFeet',
  HeightInches = 'heightInches',
  WeightLbs = 'weightLbs',
  Email = 'email',
}

const GetStarted: FC = () => {
  const [formState, setFormState] = useState<HeightWeightForm>({
    firstName: '',
    lastName: '',
    heightFeet: '',
    heightInches: '',
    weightLbs: '',
    email: '',
    bmi: '',
  });
  const [errors, setErrors] = useState<HeightWeightForm | {}>({});

  const routePath = useRoutePath();

  const navigate = useNavigate();

  const {
    experimentState: { isInitialized },
  } = useExperiment();

  useEffect(() => {
    const anonymousProgress = getAnonymousProgress();

    if (anonymousProgress) {
      const form = anonymousProgress[ProgressKey.GetStarted];
      const initialFormState = {
        firstName: form?.firstName ?? '',
        lastName: form?.lastName ?? '',
        heightFeet: form?.heightFeet ?? '',
        heightInches: form?.heightInches ?? '',
        weightLbs: form?.weightLbs ?? '',
        bmi:
          form?.weightLbs && form?.heightFeet && form?.heightInches
            ? getBMI({
                weight: Number(form.weightLbs),
                feet: Number(form.heightFeet),
                inches: Number(form.heightInches),
              }).toString()
            : '',
      };

      setFormState({ ...initialFormState, email: form?.email ?? '' });
    }
  }, []);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const field = e.target.id;
    const value = e.target.value;

    let errorMessage = '';

    if (!value) {
      errorMessage = 'This field is required';
    } else if (field === InputFields.HeightFeet) {
      errorMessage = !isHeightFeetValid(value) ? 'Minimum must be 1 and maximum 10' : '';
    } else if (field === InputFields.HeightInches) {
      errorMessage = !isHeightInchesValid(value) ? 'Minimum must be 0 and maximum 11' : '';
    } else if (field === InputFields.WeightLbs) {
      errorMessage = !isWeightLbsValid(value)
        ? 'Weight field minimum must be 10 and maximum 999'
        : '';
    } else if (field === InputFields.Email) {
      errorMessage = !isEmailValid(value)
        ? 'Sorry, only letters (a-z), numbers (0-9), and periods (.) are allowed.'
        : '';
    }

    setErrors({ ...errors, [field]: errorMessage });

    if (
      [InputFields.FirstName, InputFields.LastName, InputFields.Email].includes(
        field as InputFields
      ) ||
      !value
    ) {
      setFormState({ ...formState, [field]: value });
    } else {
      setFormState({ ...formState, [field]: value.replace(/\D/g, '') });
    }
  };

  const handleSubmit = (): void => {
    const updatedFormState = {
      ...formState,
      heightFeet: formState.heightFeet,
      heightInches: formState.heightInches,
      weightLbs: formState.weightLbs,
      bmi: getBMI({
        weight: Number(formState.weightLbs),
        feet: Number(formState.heightFeet),
        inches: Number(formState.heightInches),
      }).toString(),
    };

    updatedFormState.email = formState.email;

    storeAnonymousProgress(ProgressKey.GetStarted, updatedFormState);

    const isNotQualifiedByBMI = isLowBMI({
      weight: +updatedFormState.weightLbs,
      feet: +updatedFormState.heightFeet,
      inches: +updatedFormState.heightInches,
    });

    const anonymousProgress = getAnonymousProgress()!;

    const goals = anonymousProgress[ProgressKey.ProgramGoals];
    const getStarted = anonymousProgress[ProgressKey.GetStarted];

    const leadPayload = {
      meta: {
        systemSource: ContactMethod.Funnel,
      },
      patient: {
        bmi: Number(getStarted.bmi),
        email: formState.email!,
        feet: Number(getStarted.heightFeet),
        firstName: getStarted.firstName,
        healthTarget: goals.join(', '),
        inches: Number(getStarted.heightInches),
        lastName: getStarted.lastName,
        weight: Number(getStarted.weightLbs),
        ...(isNotQualifiedByBMI && { notQualified: NotQualified.BMI }),
      },
    };

    onboardingClient.upsertLead(leadPayload).then((success) => {
      if (!success) {
        return;
      }

      // TODO: Delete and let the update be centralized in the app index ¿?
      if (isInitialized) {
        MixpanelClient.setMetadata({
          email: formState.email!,
          experiments: StatsigManager.getExperimentsSummary(),
        });

        StatsigManager.logEvent({
          eventName: StatsigEventName.LeadCreation,
          metadata: {
            email: leadPayload.patient.email,
            systemSource: ContactMethod.Funnel,
          },
        });
      }
    });

    if (isNotQualifiedByBMI) {
      navigateToPage({ targetPage: '/low-bmi', navigate });
    } else {
      navigateToPage({ targetPage: '/about', navigate });
    }
  };

  return (
    <BasicLayout
      title='Let’s get started'
      subtitle='Let’s get some more details about you and see about your insurance coverage'
      buttonProps={{
        disabled:
          Object.entries(formState).some(([key, value]) =>
            key !== 'bmi' ? value.length === 0 : false
          ) || Object.values(errors).some((i) => i && i.length >= 0),
        onClick: handleSubmit,
      }}
      back>
      <Stack spacing='24px'>
        <Box>
          <Typography paragraph variant='h4'>
            Hello,
          </Typography>
          <Stack direction={{ xs: 'column', md: 'row' }} spacing='10px' mb='10px'>
            <ElementTracker
              routePath={routePath}
              name='First Name'
              type={ElementTrackerType.Blurrable}>
              <TextField
                id={InputFields.FirstName}
                label={'First Name'}
                variant='filled'
                type={'text'}
                value={formState.firstName || ''}
                className='get-started-input'
                onChange={handleChange}
                error={!!errors[InputFields.FirstName as keyof typeof errors]}
                helperText={
                  !!errors[InputFields.FirstName as keyof typeof errors]
                    ? errors[InputFields.FirstName as keyof typeof errors]
                    : ''
                }
              />
            </ElementTracker>
            <ElementTracker routePath={routePath} name='Last Name'>
              <TextField
                id={InputFields.LastName}
                label={'Last Name'}
                variant='filled'
                type={'text'}
                value={formState.lastName || ''}
                className='get-started-input'
                onChange={handleChange}
                error={!!errors[InputFields.LastName as keyof typeof errors]}
                helperText={
                  !!errors[InputFields.LastName as keyof typeof errors]
                    ? errors[InputFields.LastName as keyof typeof errors]
                    : ''
                }
              />
            </ElementTracker>
          </Stack>
          <Stack direction={{ xs: 'column', md: 'row' }} spacing='10px'>
            <ElementTracker routePath={routePath} name='Email'>
              <TextField
                id={InputFields.Email}
                label={'Email'}
                variant='filled'
                type={'text'}
                value={formState.email || ''}
                className='get-started-input'
                onChange={handleChange}
                error={!!errors[InputFields.Email as keyof typeof errors]}
                helperText={
                  !!errors[InputFields.Email as keyof typeof errors]
                    ? errors[InputFields.Email as keyof typeof errors]
                    : ''
                }
              />
            </ElementTracker>
          </Stack>
        </Box>
        <Box>
          <Typography variant={'h4'}>What’s your height and weight?</Typography>
          <Typography paragraph variant={'h5'} className='typography-bmi'>
            (This calculates your BMI)
          </Typography>
          <Stack spacing='10px'>
            <Stack direction={{ xs: 'column', md: 'row' }} spacing='10px'>
              <ElementTracker routePath={routePath} name='Height Feet'>
                <TextField
                  id={InputFields.HeightFeet}
                  label={'ft.'}
                  variant='filled'
                  inputProps={{ placeholder: 'ft.' }}
                  value={formState.heightFeet || ''}
                  className='get-started-input'
                  onChange={handleChange}
                  error={!!errors[InputFields.HeightFeet as keyof typeof errors]}
                  helperText={
                    !!errors[InputFields.HeightFeet as keyof typeof errors]
                      ? errors[InputFields.HeightFeet as keyof typeof errors]
                      : ''
                  }
                />
              </ElementTracker>
              <ElementTracker routePath={routePath} name='Height Inches'>
                <TextField
                  id={InputFields.HeightInches}
                  label={'in.'}
                  variant='filled'
                  inputProps={{ placeholder: 'in.' }}
                  value={formState.heightInches || ''}
                  className='get-started-input'
                  onChange={handleChange}
                  error={!!errors[InputFields.HeightInches as keyof typeof errors]}
                  helperText={
                    !!errors[InputFields.HeightInches as keyof typeof errors]
                      ? errors[InputFields.HeightInches as keyof typeof errors]
                      : ''
                  }
                />
              </ElementTracker>
            </Stack>
            <ElementTracker routePath={routePath} name='Weight Lbs'>
              <TextField
                id={InputFields.WeightLbs}
                label={'lbs.'}
                variant='filled'
                value={formState.weightLbs || ''}
                className='get-started-input'
                inputProps={{ placeholder: 'lbs.' }}
                onChange={handleChange}
                error={!!errors[InputFields.WeightLbs as keyof typeof errors]}
                helperText={
                  !!errors[InputFields.WeightLbs as keyof typeof errors]
                    ? errors[InputFields.WeightLbs as keyof typeof errors]
                    : ''
                }
              />
            </ElementTracker>
          </Stack>
        </Box>
      </Stack>
      <Box mt={2}>
        <TermsOfUse />
      </Box>
    </BasicLayout>
  );
};

export default withProgress(GetStarted, Page.GetStarted);
