import {
  ContactMethod,
  ENARA_BLOG_URL,
  ENARA_INSTAGRAM_URL,
  ENARA_URL,
  ElementTracker,
  ElementTrackerType,
  NotQualified,
  ProgressKey,
  crmClient,
  getAnonymousProgress,
  isEmailValid,
  wipeOutAnonymousProgress,
} from '@enaratech/funnel-helper';
import { Box, Link, TextField, Typography } from '@mui/material';
import { FC, useState } from 'react';
import { Page, withProgress } from 'src/components/Common/Progress/Progress';
import Toast from 'src/components/Common/Toast/Toast';
import BasicLayout from 'src/components/Layout/BasicLayout/BasicLayout';
import { useClinic } from 'src/contexts/clinic';
import { useRoutePath } from 'src/hooks/useRoutePath';

const EMAIL_FIELD_NAME = 'email';

const NotAvailable: FC = () => {
  const [isEnaraInfoVisible, setIsEnaraInfoVisible] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [formState, setFormState] = useState<{ email: string }>({ email: '' });
  const [errors, setErrors] = useState<{ email: string } | {}>({});

  const { clinicState } = useClinic();
  const routePath = useRoutePath();

  const validateForm = (field: string, value: string) => {
    const updatedFormState = { ...formState, [field]: value };

    if (!updatedFormState[field as keyof typeof updatedFormState]) {
      setErrors({ ...errors, [field]: `The ${field.toLowerCase()} is required` });
    }

    if (updatedFormState[field as keyof typeof updatedFormState].length > 0) {
      setErrors({ ...errors, [field]: '' });
    }

    if (field === 'email' && updatedFormState.email && !isEmailValid(updatedFormState.email)) {
      setErrors({
        ...errors,
        [field]: 'Sorry, only letters (a-z), numbers (0-9), and periods (.) are allowed.',
      });
    }

    setFormState(updatedFormState);
  };

  const handleClick = async (): Promise<void> => {
    const anonymousProgress = getAnonymousProgress();

    if (!anonymousProgress) {
      return Toast.notification('error', 'It was not possible to reserve your seat');
    }

    setIsSubmitting(true);

    const goals = anonymousProgress[ProgressKey.ProgramGoals];
    const getStarted = anonymousProgress[ProgressKey.GetStarted];
    const partner = anonymousProgress[ProgressKey.Partner];

    if (
      await crmClient.createLead({
        contactMethod: ContactMethod.Funnel,
        notQualified: NotQualified.Location,
        firstName: getStarted.firstName,
        lastName: getStarted.lastName,
        heightInches: +getStarted.heightInches,
        heightFeet: +getStarted.heightFeet,
        weightLbs: +getStarted.weightLbs,
        email: formState.email,
        city: clinicState!.patient.city,
        state: clinicState!.patient.state,
        zipCode: partner.zipCode,
        goals,
      })
    ) {
      wipeOutAnonymousProgress();

      setIsEnaraInfoVisible(true);
    } else {
      Toast.notification('error', 'It was not possible to reserve your seat');
    }

    setIsSubmitting(false);
  };

  const getSubtitle = () => {
    if (isEnaraInfoVisible) {
      return 'Awesome! We will let you know when we’re in your area';
    }

    return (
      <Typography variant={'h4'}>
        &nbsp;We will let you know when we are in your area. To learn more about your future weight
        loss journey, visit{' '}
        <Link href={ENARA_URL} underline='hover' target={'_blank'}>
          {'enarahealth.com'}
        </Link>
      </Typography>
    );
  };

  return (
    <BasicLayout
      title="Thanks for your interest in Enara's Program!"
      subtitle={getSubtitle()}
      {...(!isEnaraInfoVisible && {
        buttonProps: {
          text: 'Subscribe',
          disabled:
            Object.values(formState).some((i) => i.length === 0) ||
            Object.values(errors).some((i) => i.length > 0),
          loading: isSubmitting,
          onClick: handleClick,
        },
      })}
      back={!isEnaraInfoVisible}>
      {isEnaraInfoVisible ? (
        <>
          <Typography>
            Meanwhile, check out{' '}
            <ElementTracker
              routePath={routePath}
              name='Go to Blog link'
              type={ElementTrackerType.Clickable}>
              <Link href={ENARA_BLOG_URL} target='_blank'>
                our blog
              </Link>
            </ElementTracker>{' '}
            to get inspired.
          </Typography>

          <Typography>
            Follow Enara Health{' '}
            <ElementTracker
              routePath={routePath}
              name='Go to Instagram link'
              type={ElementTrackerType.Clickable}>
              <Link href={ENARA_INSTAGRAM_URL} target='_blank'>
                on Instagram
              </Link>
            </ElementTracker>{' '}
            for health advice from some of the top doctors in the country!!
          </Typography>
        </>
      ) : (
        <Box mt={5}>
          <Typography paragraph variant='h4'>
            Our newsletter is a helpful mix of tips for healthy eating, fighting temptations, and
            easy-to-make recipes. Sign up with your email below
          </Typography>
          <ElementTracker routePath={routePath} name='Email' type={ElementTrackerType.Blurrable}>
            <TextField
              id='notAvailable-filled-basic'
              label='Enter email'
              variant='filled'
              type='text'
              fullWidth
              autoComplete={EMAIL_FIELD_NAME}
              onChange={(e) => validateForm(EMAIL_FIELD_NAME, e.target.value)}
              error={!!errors[EMAIL_FIELD_NAME as keyof typeof errors]}
              helperText={
                !!errors[EMAIL_FIELD_NAME as keyof typeof errors]
                  ? errors[EMAIL_FIELD_NAME as keyof typeof errors]
                  : ''
              }
            />
          </ElementTracker>
        </Box>
      )}
    </BasicLayout>
  );
};

export default withProgress(NotAvailable, Page.NotAvailable);
